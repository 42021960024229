import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [


    {
        path: '/headmain',
        name: 'headmain',
        component: () => import( '../views/headmain.vue')
    },
    {
        path: '/headother',
        name: 'headother',
        component: () => import( '../views/headother.vue')
    },
    {
        path: '/zyzhs',
        name: '中央指挥室',
        component: () => import( '../views/中央指挥室.vue')
    },
    {
        path: '/dtzt',
        name: '/地铁站台',
        component: () => import( '../views/地铁站台.vue')
    },
    {
        path: '/csc',
        name: '/菜市场',
        component: () => import('../views/菜市场.vue')
    },
    {
        path: '/',
        name: '/雨露游戏站',
        component: () => import('../views/雨露游戏站.vue')
    },
    {
        path: '/kfw',
        name: '/咖啡屋',
        component: () => import('../views/咖啡屋.vue')
    },
    {
        path: '/userinfo',
        name: '/userinfo',
        component: () => import('../views/用户资料页面.vue')
    },
    {
        path: '/usermsg',
        name: '/usermsg',
        component: () => import('../views/用户消息.vue')
    },
    {
        path: '/creatcenter',
        name: '/creatcenter',
        component: () => import('../views/创作中心.vue')
    },
    {
        path: '/uservip',
        name: '/uservip',
        component: () => import('../views/用户会员.vue')
    },
    {
        path: '/usertable',
        name: '/usertable',
        component: () => import('../views/用户桌面.vue')
    },
    {
        path: '/yjpp',
        name: '/yjpp',
        component: () => import('../views/一键匹配项目例.vue')
    },
    {
        path: '/yjppcg',
        name: '/yjppcg',
        component: () => import('../views/一键匹配成功后.vue')
    },
    {
        path: '/zzlt',
        name: '/zzlt',
        component: () => import('../views/战争雷霆.vue')
    },
    {
        path: '/mlssj',
        name: '/mlssj',
        component: () => import('../views/美丽水世界.vue')
    },
    {
        path: '/wbzzgc',
        name: '/wbzzgc',
        component: () => import('../views/外包广场作者.vue')
    },
    {
        path: '/wbzzgczzl',
        name: '/wbzzgczzl',
        component: () => import('../views/外包广场创作者例.vue')
    },
    {
        path: '/wbzzgcfwl',
        name: '/wbzzgcfwl',
        component: () => import('../views/外包广场服务例.vue')
    },
    {
        path: '/zzltcg',
        name: '/zzltcg',
        component:()=>import('../views/战争雷霆成功.vue')
    },
    {
        path: '/wbzzgcfwl',
        name: '/wbzzgcfwl',
        component:()=>import('../views/外包广场服务例.vue')
    },
    {
        path: '/mlssjcg',
        name: '/mlssjcg',
        component:()=>import('../views/美丽水世界成功.vue')
    }


]

const router = new VueRouter({
    routes
})

export default router
