import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {//数据仓库
  },
  getters: {//和计算属性类似
  },
  mutations: {//修改数据仓库中的数据 vue官方要求 修改数据必须要求使用这里的方法
  },
  actions: {//修改数据仓库中的数据 支持异步操作
  },
  modules: {//模块化 如果有多个仓库 可以用模块给分开
  }
})
